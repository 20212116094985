<template>
  <div class="outer_container">
    <div class="orig_container pc_version">
      <div class="hover_elem_container">
        <div class="hover_elem first_elem"
             @mouseenter="changeVisibility('biking')"
             @mouseleave="changeVisibility('biking')">
          <Transition>
            <div class="text_info" v-if="!bike_img_visible">
              <p class="caps heading">{{ $t('biking') }}</p>
              <br>
              <p class="text">{{ $t('biking_paragraph_1') }}</p>
              <br>
              <p class="text additional_text">{{ $t('biking_paragraph_2') }}</p>
            </div>
          </Transition>
        </div>
        <p class="caps">{{ $t('biking') }}</p>
      </div>
      <div class="hover_elem_container">
        <div class="hover_elem second_elem"
             @mouseenter="changeVisibility('sup')"
             @mouseleave="changeVisibility('sup')">
          <Transition>
            <div class="text_info" v-if="!sup_img_visible">
              <p class="caps heading">{{ $t('sup') }}</p>
              <br>
              <p class="text">{{ $t('sup_paragraph_1') }}</p>
              <br>
              <p class="text additional_text">{{ $t('sup_paragraph_2') }}</p>
            </div>
          </Transition>
        </div>
        <p class="caps">{{ $t('sup') }}</p>
      </div>
      <div class="hover_elem_container">
        <div class="hover_elem third_elem"
             @mouseenter="changeVisibility('hot_tub')"
             @mouseleave="changeVisibility('hot_tub')">
          <Transition>
            <div class="text_info" v-if="!hot_tub_img_visible">
              <p class="caps heading">{{ $t('hot_tub') }}</p>
              <br>
              <p class="text">{{ $t('hot_tub_paragraph_1') }}</p>
              <br>
              <p class="text additional_text">{{ $t('hot_tub_paragraph_2') }}</p>
            </div>
          </Transition>
        </div>
        <p class="caps">{{ $t('hot_tub') }}</p>
      </div>
      <div class="hover_elem_container">
        <div class="hover_elem forth_elem"
             @mouseenter="changeVisibility('reading')"
             @mouseleave="changeVisibility('reading')">
          <Transition>
            <div class="text_info" v-if="!reading_img_visible">
              <p class="caps heading">{{ $t('reading') }}</p>
              <br>
              <p class="text">{{ $t('reading_paragraph_1') }}</p>
            </div>
          </Transition>
        </div>
        <p class="caps">{{ $t('reading') }}</p>
      </div>
    </div>

    <div class="tablet_version">
      <div class="flex_container">
        <div class="tablet_elem">
          <img src="@/assets/images/recreation/slider_1.png" loading="lazy">
          <br>
          <p class="caps heading">{{ $t('biking') }}</p>
          <br>
          <p class="text">{{ $t('biking_paragraph_1') }}</p>
          <br>
          <p class="text">{{ $t('biking_paragraph_2') }}</p>
        </div>
        <div class="tablet_elem">
          <img src="@/assets/images/recreation/slider_2.png" loading="lazy">
          <br>
          <p class="caps heading">{{ $t('sup') }}</p>
          <br>
          <p class="text">{{ $t('sup_paragraph_1') }}</p>
          <br>
          <p class="text">{{ $t('sup_paragraph_2') }}</p>
        </div>
      </div>
      <div class="flex_container">
        <div class="tablet_elem">
          <img src="@/assets/images/recreation/slider_3.png" loading="lazy">
          <br>
          <p class="caps heading">{{ $t('hot_tub') }}</p>
          <br>
          <p class="text">{{ $t('hot_tub_paragraph_1') }}</p>
          <br>
          <p class="text">{{ $t('hot_tub_paragraph_2') }}</p>
        </div>
        <div class="tablet_elem">
          <img src="@/assets/images/recreation/slider_4.png" loading="lazy">
          <br>
          <p class="caps heading">{{ $t('reading') }}</p>
          <br>
          <p class="text">{{ $t('reading_paragraph_1') }}</p>
        </div>
      </div>
    </div>

    <div class="mobile_version">
      <div class="mobile_elem">
        <img src="@/assets/images/recreation/slider_1.png" loading="lazy">
        <br>
        <p class="caps heading">{{ $t('biking') }}</p>
        <br>
        <p class="text">{{ $t('biking_paragraph_1') }}</p>
        <br>
        <p class="text">{{ $t('biking_paragraph_2') }}</p>
      </div>
      <div class="mobile_elem">
        <img src="@/assets/images/recreation/slider_2.png" loading="lazy">
        <br>
        <p class="caps heading">{{ $t('sup') }}</p>
        <br>
        <p class="text">{{ $t('sup_paragraph_1') }}</p>
        <br>
        <p class="text">{{ $t('sup_paragraph_2') }}</p>
      </div>
      <div class="mobile_elem">
        <img src="@/assets/images/recreation/slider_3.png" loading="lazy">
        <br>
        <p class="caps heading">{{ $t('hot_tub') }}</p>
        <br>
        <p class="text">{{ $t('hot_tub_paragraph_1') }}</p>
        <br>
        <p class="text">{{ $t('hot_tub_paragraph_2') }}</p>
      </div>
      <div class="mobile_elem">
        <img src="@/assets/images/recreation/slider_4.png" loading="lazy">
        <br>
        <p class="caps heading">{{ $t('reading') }}</p>
        <br>
        <p class="text">{{ $t('reading_paragraph_1') }}</p>
      </div>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecreationSlider',
  data() {
    return {
      bike_img_visible: true,
      sup_img_visible: true,
      hot_tub_img_visible: true,
      reading_img_visible: true
    }
  },
  methods: {
    changeVisibility(field) {
      switch (field) {
        case 'biking':
          this.bike_img_visible = !this.bike_img_visible
          break
        case 'sup':
          this.sup_img_visible = !this.sup_img_visible
          break
        case 'hot_tub':
          this.hot_tub_img_visible = !this.hot_tub_img_visible
          break
        case 'reading':
          this.reading_img_visible = !this.reading_img_visible
          break
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-enter-active, .v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.outer_container {
  background: rgba(91, 102, 112, 0.2);
}
.orig_container {
  height: 50vh;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*overflow: hidden;*/
}
.mobile_version {
  display: none;
}
.tablet_version {
  display: none;
}
.hover_elem_container {
  min-height: 300px;
  height: 80%;
  width: 23%;
  max-height: 452px;
  max-width: 339px;
}
.hover_elem {
  width: 100%;
  height: 100%;
  /*height: 100%;*/
  max-width: 339px;
  max-height: 452px;
  overflow: scroll;
}
.first_elem {
  background-image:url("@/assets/images/recreation/slider_1.png");
}
.first_elem:hover {
  background:url("@/assets/images/recreation/slider_1.png") rgba(13, 13, 14, 0.9);
}
.second_elem {
  background-image:url("@/assets/images/recreation/slider_2.png");
}
.second_elem:hover {
  background:url("@/assets/images/recreation/slider_2.png") rgba(13, 13, 14, 0.9);
}
.third_elem {
  background-image:url("@/assets/images/recreation/slider_3.png");
}
.third_elem:hover {
  background:url("@/assets/images/recreation/slider_3.png") rgba(13, 13, 14, 0.9);
  opacity: 1;
}
.forth_elem {
  background-image:url("@/assets/images/recreation/slider_4.png");
}
.forth_elem:hover {
  background:url("@/assets/images/recreation/slider_4.png") rgba(13, 13, 14, 0.9);
  opacity: 1;
}
.hover_elem {
  background-size:cover;
  background-blend-mode: multiply;
}
.hover_elem:hover {
  cursor: pointer;
  background-size:cover;
  background-blend-mode: multiply;
}
p.caps {
  color: var(--back_color);
}
.hidden {
  display: none;
}
.lower_opacity {
  opacity: 0.2;
}
p.heading {
  margin-left: 8%;
  padding-top: 10%;
  color: var(--white_color);
}
p.text {
  width: 84%;
  margin-left: 8%;
  color: var(--white_color);
}
@media (max-width: 1350px) {
  p.heading {
    padding-top: 5%;
  }
}
@media (max-width: 1300px) {
  p.heading {
    padding-top: 20%;
  }
  .additional_text {
    display: none;
  }
}
@media (max-width: 1023px) {
  .orig_container.pc_version {
    display: none;
  }
  .flex_container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
  }
  .tablet_version {
    display: block;
    align-items: center;
    width: calc(100vw - 100px);
    margin: auto;
    padding-top: 50px;
  }
  .tablet_elem {
    width: 45%;
  }
  p.caps.heading {
    margin-left: 0;
    padding-top: 0;
    margin-top: 10px;
    color: var(--back_color);
    width: 100%;
  }
  p.text {
    color: var(--back_color);
    margin-left: 0;
    width: 100%
  }
  .tablet_elem > img {
    width: 100%;
  }
}
@media (max-width: 744px) {
  .mobile_version {
    display: block;
  }
  .orig_container {
    display: block;
  }
  .tablet_version {
    display: none;
  }
  .mobile_elem {
    width: calc(100vw - 60px);
    margin-left: 30px;
  }
  .mobile_elem > img {
    width: 100%;
  }
  .mobile_elem > img:nth-child(1) {
    margin-top: 30px;
  }
}
@media (max-width: 576px) {
  .mobile_elem {
    width: calc(100vw - 30px);
    margin-left: 15px;
  }
}
</style>