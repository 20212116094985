<template>
  <div class="flex_container">
    <div class="left_container">
      <img src="@/assets/images/sustainability/content_photo_1.png" loading="lazy">
      <p class="paragraph_one text_one">
        {{ $t('sustainability_paragraph_1') }}
      </p>
    </div>
    <div class="right_container">
      <p class="paragraph_one text_two">
        {{ $t('sustainability_paragraph_2') }}
      </p>
      <img src="@/assets/images/sustainability/content_photo_2.png" loading="lazy">
    </div>
    <div class="mobile_right_container">
      <img src="@/assets/images/sustainability/content_photo_2.png" loading="lazy">
      <p class="paragraph_one text_two">
        {{ $t('sustainability_paragraph_2') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SustainabilityBody',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.flex_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12vh;
  margin-bottom: 12vh;
}
.left_container {
  width: 43%;
}
.right_container {
  width: 47%;
}
img {
  width: 100%;
}
img:nth-child(1) {
  max-height: 80vh;
  object-fit: cover;
}
img:nth-child(2) {
  max-height: 100vh;
  object-fit: cover;
}
.paragraph_one {
  width: 50%;
}
.paragraph_one:nth-child(2) {
  margin-left: 50%;
  margin-top: 35%;
}
.paragraph_one:nth-child(1) {
  margin-bottom: 20%;
}
.mobile_right_container {
  display: none;
}
@media (max-width: 744px) {
  .flex_container {
    width: 100%;
    justify-content: space-between;
    margin-top: 6vh;
    margin-bottom: 6vh;
  }
  .paragraph_one {
    width: 80%;
  }
  .paragraph_one:nth-child(2) {
    margin-left: 20%;
    margin-top: 35%;
  }
  .paragraph_one:nth-child(1) {
    margin-bottom: 15%;
  }
}
@media (max-width: 550px) {
  .flex_container {
    display: block;
  }

  .right_container {
    display: none;
  }
  .left_container {
    width: 100%;
  }
  .mobile_right_container {
    display: block;
    width: 100%;
  }
  .paragraph_one {
    width: 100%;
  }
  .paragraph_one:nth-child(2) {
    margin: 5vh 0;
  }
  .paragraph_one:nth-child(1) {
    margin-bottom: 5vh;
  }
}
</style>