<template>
  <div class="flex_container content_container">
    <div class="first_container">
      <img src="@/assets/images/holiday_houses/content_photo_1.png" loading="lazy">
    </div>
    <div class="description_container">
      <p class="paragraph_one text_one">
        {{ $t('holiday_text_1') }}
      </p>
      <img src="@/assets/images/holiday_houses/content_photo_2.png" loading="lazy">
      <p class="paragraph_one text_two">
        {{ $t('holiday_text_2') }}
      </p>
    </div>
  </div>
  <img class="single_image" src="@/assets/images/holiday_houses/content_photo_3.png" alt="" loading="lazy">
</template>

<script>
export default {
  name: 'HolidayHousesBody',
  methods: {
    track () {
      this.$gtag.event('screen_view',
          {
            app_name: 'maajo',
            screen_name: 'Sustainability main'
          })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex_container {
  display: flex;
}
.first_container {
  width: 43%;
}
.description_container {
  width: 47%;
  margin-top: 10vh;
}
.content_container {
  margin-top: 10vh;
  margin-bottom: 10vh;
  justify-content: space-between;
}
.first_container > img {
  width: 100%;
}
.text_one {
  width: 80%;
  margin-bottom: 10vh;
}
.text_two {
  width: 60%;
  margin-top: 5vh;
}
.description_container > img {
  width: 100%;
}
.single_image {
  width: 100%;
}
@media (max-width: 1300px) {
  .description_container {
    margin-top: 3vh;
  }
  .text_one {
    margin-bottom: 5vh;
  }
}
@media (max-width: 1200px) {
  .description_container {
    margin-top: 1vh;
  }
  .text_one {
    margin-bottom: 3vh;
  }
  .text_two {
    margin-top: 3vh;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 900px) {
  .first_container {
    margin-top: 5vh;
  }
  .description_container {
    margin-top: 0;
  }
}
@media (max-width: 680px) {
  .content_container {
    margin-top: 6vh;
    margin-bottom: 4vh;
    display: block;
  }
  .first_container {
    width: 100%;
  }
  .description_container {
    width: 100%;
  }
  p.paragraph_one {
    width: 100%;
  }
  p.paragraph_one:nth-child(1) {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  p.paragraph_one:nth-child(1) {
    margin-top: 4vh;
  }
}
</style>