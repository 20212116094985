export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where the time goes slowly"])},
  "basic_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a place for you to take a break and escape from all the commotion."])},
  "basic_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO has been a family passion project and a quest to create the perfect holiday house. A place to experience the rejuvenating sense of calm that brings us inner strength and confidence. The beautiful, relaxing, and minimalist Nordic style interior combined with the soothing ambiance of the Baltic seaside and the charming meadows allow our guests to encounter true peace."])},
  "basic_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The large glass surfaces allow you to be immersed and reconnect with the beauties of countryside nature. Without leaving the comforts of city life."])},
  "basic_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimalist interior won’t distract you from the wonders that await outside: majestic pine treas, graceful junipers and mysterious heathers bordering the natural seaside meadow."])},
  "holiday_houses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["holiday houses"])},
  "holiday_houses_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood, linen, and glass – these materials create a pleasant blend of rustic and modern interior."])},
  "holiday_houses_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO houses are equipped with the most efficient recuperation and air conditioning systems that will keep you cool in the summer and warm in the winter."])},
  "holiday_houses_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And there is enough space for the whole family or a group of 5 friends! There is a spacious double bed, a comfortable double roll-up mattress, and a nice single roll-up."])},
  "bathroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bathroom"])},
  "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terrace"])},
  "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedroom"])},
  "living_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["living_room"])},
  "bedroom_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A full night's sleep is essential for so many reasons. That's why each holiday house has a separate bedroom with an open space corridor leading to the rest of the house."])},
  "bedroom_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A spacious double bed, a comfortable double roll-up mattress, and a nice single roll-up can accommodate up to 5 guests. Custom-made linen bed sheets ensure the best sleeping conditions year-round: warm in the winter, cool in the summer. The soft and natural touch of this Nordic palette linen, the fine down pillows and blankets – these are the key ingredients for the best possible dreams at night."])},
  "bedroom_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The large glass facade offers a uniquely immersive experience. It's almost as if you would wake up in the middle of the seaside meadow! Feeling the sun's warm rays on your face peeking through the cozy linen curtains... Feeling productive? There is a handy table for reading, remote working or studying, and a Wi-Fi network."])},
  "living_room_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A blank canvas for your unbound creativity."])},
  "living_room_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What story will you conjure today? Is it a story of a cozy evening by the fireplace? Is it a writing session by the sturdy, minimalist desk? Who are you writing to – your friends, family, your future self? Or perhaps, something behind the massive window caught your eye, and now you’re making a quick sketch... sitting on the comfy linen sofa... taking in the incredible scenery... The gentle energy of this room brings out the hidden inspiration we all possess."])},
  "living_room_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The MAAJO signature combination of wood, linen, and glass creates the most welcoming space for you to thrive."])},
  "terrace_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As the morning dew may kiss the grass or the evening mists have come to pass - you can be a part of nature's beautiful dance throughout the day."])},
  "terrace_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each house features two outdoor terraces: one is facing South-East, the other faces North-West. Should you prefer drowsing in the sun or relaxing in the shadow - the time of the day won't limit you too much. Terraces are equipped with dining tables, seats, lounge chairs, and sunbeds."])},
  "terrace_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "bathroom_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning, evening, midday – doesn’t matter. "])},
  "bathroom_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is nothing like a nice, refreshing shower. After all, humans are at least 60% water! This classic white tile minimalist bathroom is compact,  functional, and even features floor heating."])},
  "bathroom_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The large glass facade offers a uniquely immersive experience. It's almost as if you would wake up in the middle of the seaside meadow! Feeling the sun's warm rays on your face peeking through the cozy linen curtains... Feeling productive? There is a handy table for reading, remote working or studying, and a Wi-Fi network."])},
  "holiday_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stylish black minimalist kitchen comes fully equipped with all the appliances you will need: stove, oven, microwave, fridge, dishwasher, and even a washing machine. We have prepared everything to guarantee maximum convenience during your stay. The sturdy dining table can accommodate 5 people. Enjoy a strong cup of the finest Illy coffee or a smooth, calming Latvian herbal tea here!"])},
  "holiday_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unwind on the comfortable linen sofa while enjoying a gorgeous view or warm up by lighting the fireplace."])},
  "sustainability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustainability"])},
  "sustainability_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in sustainability - the shared responsibility towards our planet."])},
  "sustainability_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in sustainability - the shared responsibility towards our planet. That's why our houses are built of ethically-sourced, cross-laminated timber. "])},
  "sustainability_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We chose the charred wood technique for the facade because that is the most natural way of weatherproofing the house. And it brings out the hidden beauty of the wooden texture."])},
  "recreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recreation"])},
  "recreation_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is so much to see, do and enjoy in this picturesque corner of Latvia."])},
  "recreation_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can hop on a bike and travel to nearby beaches and villages - full of alluring stories and fascinating craftsmanship."])},
  "recreation_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or have a nice picnic on a remote beach on your way to Upesgrīva - with gentle waves whispering their secrets just for you."])},
  "valgalciems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valgalciems"])},
  "valgalciems_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The wild beach of Valgalciems"])},
  "valgalciems_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a lot of people know about this scenic place. But plenty of birds do! Valgalciems is an exceptional place for birdwatching."])},
  "valgalciems_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sometimes see hundreds of swans and many other species - from the good old silver gulls and black cormorants to the occasional long-tailed ducks and even the rare white-tailed eagle! If there is swimming on your mind, we recommend taking a short walk (1-3 km) towards Upesgrīva."])},
  "valgalciems_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a striking view there - great for outdoor picnics! And the Upesgrīva bay is less prone to having all kinds of water grasses showing up during windless periods."])},
  "upegriva_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upegriva beach"])},
  "upegriva_beach_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The long beach of Upesgrīva"])},
  "upegriva_beach_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you ever wondered what it's like to have your own private beach? An 800-meter drive and you are living the dream - few kilometers long white, sandy beach!"])},
  "upegriva_beach_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you ever wondered what it's like to have your own private beach? An 800-meter drive and you are living the dream!"])},
  "upegriva_beach_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kolka_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolka beach"])},
  "kolka_beach_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolka beach"])},
  "kolka_beach_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolka beach – where the Gulf of Riga meets the Great Baltic Sea. It can be dangerous to swim there, but the view is a must!"])},
  "kolka_beach_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kolka_beach_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "biking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biking"])},
  "biking_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are 6 adult and 1 kid bike available, as well as a separate bike trailer. The trailer is meant for smaller kids, it can be attached to the adult bikes. Both MAAJO houses get to share these bikes with no additional charge."])},
  "biking_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bikes are included in the price."])},
  "sup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sup"])},
  "sup_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are 2 SUP boards that you can use. We recommend taking the SUP early in the morning to enjoy a spectacular sunrise over the sea horizon. SUP can also be a fun, low-impact exercise for the whole body. Or a fresh take on meditation. Just don't forget to wear the ankle leash and be aware of the wind direction."])},
  "sup_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like the bikes, the SUP boards are included in the price, and you will be sharing them with the other house."])},
  "hot_tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hot tub"])},
  "hot_tub_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feeling a bit chilly in the evening, but don't want to go inside just yet? The hot tub might be the perfect solution then! Each MAAJO house has a separate hot tub near the terrace. Light it up and let the bubbles do their magic. "])},
  "hot_tub_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an additional service. You will find pricing and details in the booking materials."])},
  "reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reading"])},
  "reading_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right book in the right hands can inspire greatness. That's why we've assembled a collection of fashion, travel, and artist books for you."])},
  "sustainability_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can become a self-sufficient person inside a self-sufficient house. No one to interrupt your flow. Respect the local environment - and it will reward you. With peace of mind. The happiness of heart. The joys of life. Blissful tranquility."])},
  "sustainability_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When it comes to cleaning and service - only nature-friendly materials are used. We wash all the towels and bed linen solely with ecological detergents. The soap and the shower gel are also Eco-labeled. And there is a water closet connected to a biodegradable waste system. Because every little bit helps if we're to save the planet. "])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address:"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile:"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail:"])},
  "maajo_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO BOUTIQUE HOTEL, \"Veģi\", Valgalciems, Talsu novads, Latvia"])},
  "maajo_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+371 25447117"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["follow:"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facebook"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instagram"])},
  "contact_us_additional_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*We kindly ask you to respect the local people and have a peaceful, quiet stay."])},
  "contact_us_additional_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, help keep our neighbors happy and stay in the MAAJO territory and only use the provided official paths to the sea. Stay off the neighboring land. It contains state-protected biotopes and rare plant species that should not be endangered by human activity. Thank you for understanding!"])},
  "sia_maajo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA MAAJO"])},
  "registration_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reg. Nr. 43603084950"])},
  "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal address: Bauskas nov., Gailīšu pag., Uzvara, Buku iela 2, LV-3931"])},
  "bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank: Rietumu Banka"])},
  "bank_acc_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account: LV33RTMB0000700801985"])},
  "privacy_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "privacy_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO use third-party data analytics service providers in order to improve the Services Management Events may use tools from third-party service providers, such as Google Analytics, a web analytics service provided by Google, Inc. (hereinafter - Google”). Google Analytics uses \"cookies\". The information generated by the cookie about your use of the Websites is usually transmitted to a Google server in the USA and stored there. Third parties, including Google, use stored cookies to serve ads based on previous visits by a User to this site. Google may provide a service associated with website activity and internet usage in general and may transfer cookie information to third parties as required by law or as far as third parties process this information on behalf of Google. The IP address provided by Google Analytics as part of Google Analytics will not be merged with other Google data."])},
  "privacy_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can prevent the storage of cookies. In addition, you may prevent the collection by Google of the data generated by the cookie and related to your use of the website (including your IP address) and the processing of this data by Google by using the available browser plug-in: "])},
  "privacy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://tools.google.com/dlpage/gaoptout?hl=en."])},
  "book_a_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book a room"])},
  "space_for_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space for rent"])},
  "storage_premises_rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STORAGE PREMISES RENTAL"])},
  "space_for_rent_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO offers 400m2 industrial storage premises rental in \"Ulmales klubs\", Ulmale, Sakas pagasts, Dienvidkurzemes novads, LV-3466, if you are interested, please email us at"])}
}