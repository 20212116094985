<template>
  <div id="temp_container">
    <MainHeader />

    <span id="home"></span>
    <div class="orig_container">
      <div class="main_container">
        <div class="flex_container sub_container_1">
          <div class="informational_text">
            <h2>{{ $t('storage_premises_rental') }}</h2>
            <br>
            <p class="paragraph_one text_one">{{ $t('space_for_rent_paragraph') }}
              <a class="paragraph_two mt_16" href="mailto:info@maajo.com">info@maajo.com</a>.</p>
          </div>
          <div class="informational_images">
            <div class="img_box_1">
              <img src="@/assets/images/space_for_rent/map2.png">
            </div>
            <div class="img_box_2">
              <img src="@/assets/images/space_for_rent/map1.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <ContactUs />
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader';
import MainFooter from '@/components/MainFooter';
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: 'SpaceForRent',
  components: {
    ContactUs,
    MainHeader,
    MainFooter,
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: 'maajo.com/space_for_rent' })
    }
  },
}
</script>

<style scoped>

.orig_container {
  margin-bottom: 140px;
}

.sub_container_1 {
  margin-top: 10vh;
  display: flex;
}

h2 {
  width: 90%;
  margin-top: 4vh;
}

p.text_one {
  width: 90%;
}

.img_box_1 {
  width: 48%;
}
.img_box_2 {
  width: 48%;
}
.img_box_1 > img, .img_box_2 > img {
  width: 100%;
}

.informational_text {
  width: 35%;
}
.informational_images {
  display: flex;
  justify-content: space-between;
  width: 65%;
}

@media (max-width: 1300px) {
  h2 {
    margin-top: 1vh;
  }
}
@media (max-width: 1200px) {
  .informational_text {
    width: 45%;
  }
  .informational_images {
    width: 55%;
  }
  h2 {
    font-size: 28px;
  }
  .orig_container {
    margin-bottom: 100px;
  }
}
@media (max-width: 1100px) {
  .informational_text {
    width: 45%;
  }
  .informational_images {
    width: 55%;
  }
  h2 {
    font-size: 28px;
  }
}
@media (max-width: 900px) {
  .sub_container_1 {
    display: block;
    margin-top: 5vh;
  }
  h2 {
    width: 100%;
  }
  p.text_one {
    width: 100%;
  }
  .informational_text {
    width: 100%;
  }
  .informational_images {
    width: 100%;
  }
  .informational_images {
    margin-top: 5vh;
  }
  .orig_container {
    margin-bottom: 50px;
  }
}

</style>