<template>
  <div class="main_container flex_container orig_container">
    <div class="switcher_container">
      <div class="rotation_container">
        <div class="caps_s horizontal" @click="sectionSelect('bedroom')" :class="[head === 'bedroom' ? '' : opacityClass]">{{ $t('bedroom') }}</div>
        <div class="caps_s horizontal" @click="sectionSelect('living_room')" :class="[head === 'living_room' ? '' : opacityClass]">{{ $t('living_room') }}</div>
        <div class="caps_s horizontal" @click="sectionSelect('terrace')" :class="[head === 'terrace' ? '' : opacityClass]">{{ $t('terrace') }}</div>
        <div class="caps_s horizontal" @click="sectionSelect('bathroom')" :class="[head === 'bathroom' ? '' : opacityClass]">{{ $t('bathroom') }}</div>
      </div>
    </div>
    <div class="description_container">
      <div class="flex_container">
        <h2>{{ $t(head) }}</h2>
        <div class="hr_box">
          <hr>
        </div>
      </div>
      <p class="subtitle">{{ $t(subhead) }}</p>
      <br>
      <p class="paragraph_one">{{ $t(paragraph_1) }}</p>
      <br class="additional_text">
      <p class="paragraph_one additional_text">{{ $t(paragraph_2) }}</p>
    </div>
    <div class="photo_container flex_container">
      <div class="photo_box_1">
        <img :src="require(`@/assets/images/holiday_houses/${img_1_src}`)" loading="lazy">
      </div>
      <div class="photo_box_2">
        <img :src="require(`@/assets/images/holiday_houses/${img_2_src}`)" loading="lazy">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HolidayHousesSwitcher',
  data() {
    return {
      head: 'bedroom',
      subhead: 'bedroom_subtitle',
      paragraph_1: 'bedroom_paragraph_1',
      paragraph_2: 'bedroom_paragraph_2',
      img_1_src: 'bedroom_1.png',
      img_2_src: 'bedroom_2.png',
      not_defined: false,
      bedroom_visible: false,
      living_room_visible: true,
      terrace_visible: true,
      bathroom_visible: true,
      opacityClass: 'opacity',
      main_info: [
        {head: 'bedroom', subhead: 'bedroom_subtitle', paragraph_1: 'bedroom_paragraph_1', paragraph_2: 'bedroom_paragraph_2', img_1_src: 'bedroom_1.png', img_2_src: 'bedroom_2.png'},
        {head: 'living_room', subhead: 'living_room_subtitle', paragraph_1: 'living_room_paragraph_1', paragraph_2: 'living_room_paragraph_2', img_1_src: 'living_room_1.jpg', img_2_src: 'living_room_2.jpg'},
        {head: 'terrace', subhead: 'terrace_subtitle', paragraph_1: 'terrace_paragraph_1', paragraph_2: 'terrace_paragraph_2', img_1_src: 'terrace_1.jpg', img_2_src: 'terrace_2.jpg'},
        {head: 'bathroom', subhead: 'bathroom_subtitle', paragraph_1: 'bathroom_paragraph_1', paragraph_2: 'bathroom_paragraph_2', img_1_src: 'bathroom_1.jpg', img_2_src: 'bathroom_2.jpg'}
      ],
    }
  },
  methods: {
    sectionSelect(section) {
      let index = 0
      switch (section) {
        case 'bedroom':
          index = 0
          this.not_defined = false
          this.bedroom_visible = !this.bedroom_visible
          break
        case 'living_room':
          index = 1
          this.not_defined = false
          this.bedroom_visible = !this.bedroom_visible
          break
        case 'terrace':
          index = 2
          this.not_defined = true
          this.terrace_visible = !this.terrace_visible
          break
        case 'bathroom':
          index = 3
          this.not_defined = true
          this.bathroom_visible = !this.bathroom_visible
          break
      }
      this.head = this.main_info[index].head
      this.subhead = this.main_info[index].subhead
      this.paragraph_1 = this.main_info[index].paragraph_1
      this.paragraph_2 = this.main_info[index].paragraph_2
      this.img_1_src = this.main_info[index].img_1_src
      this.img_2_src = this.main_info[index].img_2_src
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex_container {
  display: flex;
}
.main_container {
  margin-top: 10vh;
  margin-bottom: 8vh;
}
.hr_new {
  width: 80%;
  float: left;
}
.caps_s {
  font-weight: var(--bold);
  font-size: var(--caps_s_pc_size);
  line-height: 28px;
  text-transform: uppercase;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  margin-left: 20px;
  cursor: pointer;
  margin-top: 50px;
}
@media (max-width: 744px) {
  .caps_s {
    font-size: var(--caps_s_tablet_size);
  }
}
@media (max-width: 350px) {
  .caps_s {
    font-size: var(--caps_s_mobile_size);
  }
}
/*.caps_s:nth-child(1) {
  margin-top: 50px;
}
.caps_s:nth-child(2),.caps_s:nth-child(3),.caps_s:nth-child(4) {
  margin-top: 50px;
}*/
.rotation_container {
  height: 200px;
  width: 100px;
}
.description_container {
  width: calc((100% - 100px)*0.43);
  /*width: 43%;*/
}
.photo_container {
  width: calc((100% - 100px)*0.57);
  /*width: 57%;*/
  justify-content: space-between;
}
.photo_box_1 {
  width: 65%;
}
.photo_box_2 {
  width: 29.11%;
}
.photo_box_1 > img, .photo_box_2 > img {
  width: 100%;
}

h2 {
  margin-top: 50px;
  margin-right: 5%;
}
.subtitle {
  width: 80%;
  margin-top: 25px;
}
.paragraph_one {
  width: 80%;
}
.opacity {
  opacity: 0.3;
}
.hidden {
  display: none;
}
@media (min-width: 1440px) {
  .hr_box {
    width: 45%;
    margin-top: 70px;
  }
}
@media (max-width: 1440px) {
  h2 {
    margin-top: 20px;
  }
  .hr_box {
    width: 35%;
    margin-top: 40px;
  }
}
@media (max-width: 1300px) {
  .additional_text {
    display: none;
  }
}
@media (max-width: 1200px) {
  .hr_box {
    width: 30%;
    margin-top: 40px;
  }
}
@media (max-width: 1050px) {
  h2 {
    margin-top: 0;
  }
  .hr_box {
    width: 20%;
    margin-top: 20px;
  }
  .caps_s:nth-child(1) {
    margin-top: 90px;
  }
  .photo_container {
    margin-top: 7vh;
  }
}
@media (max-width: 744px) {
  .main_container {
    display: block;
    margin-top: 4vh;
    margin-bottom: 6vh;
  }
  .rotation_container {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3vh;
  }
  div.caps_s.horizontal {
    writing-mode: horizontal-tb;
    transform: none;
    margin-left: 20px;
    cursor: pointer;
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
  }
  .caps_s:nth-child(1) {
    margin-top: 130px;
  }
  .caps_s:nth-child(2),.caps_s:nth-child(3) {
    margin-top: 50px;
  }
  .description_container {
    width: 100%;
  }
  .subtitle {
    width: 100%;
  }
  .paragraph_one {
    width: 100%;
  }
  .additional_text {
    display: block;
  }
  .photo_container {
    width: 100%;
    margin-top: 5vh;
  }
  .hr_box {
    width: 80%;
  }
}
@media (max-width: 450px) {
  .rotation_container {
    width: 96%;
    justify-content: space-between;
    margin: 0 auto 3vh auto;
  }
}
@media (max-width: 370px) {
  .rotation_container {
    width: 65%;
    justify-content: space-between;
    margin: 0 auto 3vh auto;
  }
  .rotation_container {
    height: 60px;
    position: relative;
  }
  .caps_s.horizontal:nth-child(3) {
    position: absolute;
    top: 40px;
    left: 0;
  }
  .caps_s.horizontal:nth-child(4) {
    position: absolute;
    top: 40px;
    right: 0;
  }
  .caps_s.horizontal:nth-child(1), .caps_s.horizontal:nth-child(2) {
    height: 28px;
  }
}
@media (max-width: 320px) {
  .rotation_container {
    width: 75%;
  }
}
@media (max-width: 250px) {
  .rotation_container {
    width: 95%;
  }
}
</style>