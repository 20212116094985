<template>
  <div class="outer_container" :style="{backgroundColor: background_color}" ref="main_container_js">
    <span :id="main_id"></span>
    <div class="main_container" :class="[images[0] === 'intro2_1.png' ? mainHolidayHouses : '',images[0] === 'intro2_3.png' ? mainRecreation : '']">
      <div class="left_side_container" :class="[images[0] === 'intro2_1.png' ? holidayHouses : '',images[0] === 'intro2_3.png' ? recreation : '']">
        <div class="content">
          <div class="heading_container">
            <hr class="hr_rule">
            <p class="caps">{{ heading }}</p>
          </div>
          <p class="decorative">{{ subheading }}</p>
          <div class="additional_description">
            <p class="paragraph_two">{{ paragraph_1 }}</p>
            <br class="extra_content">
            <p class="paragraph_two extra_content">{{ paragraph_2 }}</p>
          </div>
        </div>
      </div>
      <div class="right_side_container">
        <Transition name="image">
          <img :src="image" v-if="index === 0" loading="lazy">
        </Transition>
        <Transition name="image">
          <img :src="image" v-if="index === 1">
        </Transition>
        <Transition name="image">
          <img :src="image" v-if="index === 2">
        </Transition>
        <Transition name="image">
          <img :src="image" v-if="index === 3">
        </Transition>
        <Transition name="image">
          <img :src="image" v-if="index === 4">
        </Transition>
        <Transition name="image">
          <img :src="image" v-if="index === 5" >
        </Transition>
        <div class="left_circle">
          <div class="arrow_container" @mouseenter="rightMovement" @mouseleave="rightMovement" @click="switchImage">
            <Transition name="right_move">
              <img class="arrow_button full_visibility reverse_arrow" :class="{arrow_movement_right: rightMove}" src="@/assets/icons/Arrow2.png" alt="" loading="lazy">
            </Transition>
          </div>
          <div class="arrow_container" @mouseenter="leftMovement" @mouseleave="leftMovement" @click="switchImageBack">
            <Transition name="left_move">
              <img class="arrow_button full_visibility" :class="{arrow_movement_left: leftMove}" src="@/assets/icons/Arrow2.png" alt="" loading="lazy">
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HolidayHousesHead',
  props: {
    images: Array,
    background_color: String,
    heading: String,
    subheading: String,
    paragraph_1: String,
    paragraph_2: String,
    main_id: String
  },
  data() {
    return {
      index: 0,
      image: require(`@/assets/images/${this.images[0]}`),
      rightMove: false,
      leftMove: false,
      holidayHouses: 'holiday_houses',
      recreation: 'recreation_class',
      mainHolidayHouses: 'main_holiday_houses',
      mainRecreation: 'main_recreation_class',
    }
  },
  methods: {
    switchImage() {
      this.index = (this.index !== (this.images.length - 1)) ? this.index + 1 : 0
      this.image = require(`@/assets/images/${this.images[this.index]}`)
      if (this.$refs.main_container_js.clientWidth <= 1000) {
        this.rightMove = !this.rightMove
        setTimeout(() => this.rightMove = !this.rightMove, 250)
      }
    },
    switchImageBack() {
      this.index = (this.index !== 0) ? this.index - 1 : (this.images.length - 1)
      this.image = require(`@/assets/images/${this.images[this.index]}`)
      if (this.$refs.main_container_js.clientWidth <= 1000) {
        this.leftMove = !this.leftMove
        setTimeout(() => this.leftMove = !this.leftMove, 250)
      }
    },
    rightMovement() {
      if(this.$refs.main_container_js.clientWidth > 1000) {
        this.rightMove = !this.rightMove
      }
    },
    leftMovement() {
      if(this.$refs.main_container_js.clientWidth > 1000) {
        this.leftMove = !this.leftMove
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* image */
/*.image-enter-active {
  transition: opacity 0.3s linear;
}*/
.image-leave-active {
  transition: opacity 0.3s linear;
}

.image-enter-from {
/*  transform: translateX(-20px);*/
  opacity: 0;
}
.image-leave-to {
/*  transform: translateX(20px);*/
  opacity: 0;
}

.main_container {
  width: 100%;
  height: 70vh;
  max-height: 808px;
  display: flex;
  overflow: hidden;
}
.left_side_container {
  height: 100%;
  width: 43%;
}
.right_side_container {
  height: 100%;
  width: 57%;
  max-width: 827px;
  position: relative;
}
.right_side_container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hr_rule {
  width: 42px;
  height: 1px;
  margin-top: 13px;
  color: var(--white_color);
  margin-right: 20px;
}
.arrow_container {
  width: 64px;
  height: 64px;
  background-color: var(--arrow_white);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.arrow_movement_right {
  position: relative;
  left: 10px;
}
.arrow_movement_left {
  position: relative;
  right: 10px;

}
.arrow_container:nth-child(1) {
  bottom: 32px;
  right: 32px;
}
.arrow_container:nth-child(2) {
  bottom: 32px;
  right: 128px;
}
.arrow_container:hover {
  cursor: pointer;
}
.content {
  width: 430px;
  margin-top: 15%;
  position: relative;
}
.hidden {
  display: none;
}
@media (max-height: 830px) {
  .content {
    margin-top: 10%;
  }
  .decorative {
    font-size: 24px;
  }
}

.reverse_arrow {
  transform: rotate(180deg);
}
.arrow_button {
  width: 28px;
}
.heading_container {
  display: flex;
  width: 250px;
}
.decorative {
  margin-top: 42px;
}
.paragraph_two {
  width: 213px;
}
.paragraph_two:nth-child(1) {
  margin-top: 44px;
}
.additional_description {
  position: absolute;
  right: 0;
}
@media (max-width: 1300px) {
  .decorative {
    margin-top: 30px;
  }
  .content {
    width: 380px;
    margin-top: 10%;
  }
  .paragraph_two:nth-child(1) {
    margin-top: 25px;
  }
}

@media (min-width: 1680px) {
  .outer_container {
    width: 100vw;
  }
  .main_container {
    width: 1680px;
    margin: auto;
  }
  .left_side_container {

    padding-left: 80px;
  }

}
@media (max-width: 1024px) {
  .content {
    width: 90%;
    margin-top: 10%;
  }
  .decorative {
    width: 300px;
  }
}
@media (min-width: 1200px) and (max-width: 1680px) {
  .left_side_container {
    padding-left: 80px;
  }
}
@media (max-width: 1200px) {
  .left_side_container {
    width: 50vw;
  }
  .right_side_container {
    width: 50vw;
  }
}
@media (max-width: 1200px) {
  .left_side_container {
    padding-left: 50px;
  }
}
@media (max-width: 1024px) {
  .left_side_container {
    padding-left: 50px;
  }
  .arrow_container {
    opacity: 0.6;
  }
  img.arrow_button {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .left_side_container {
    padding-left: 30px;
  }
}
@media (max-width: 744px) {
  .main_container {
    height: 1031px;
    max-height: 1071px;
    /*min-height: 800px;*/
    display: block;
  }
  .left_side_container {
    height: 320px;
    /*max-height: 40%;*/
    width: 100%;
  }
  .holiday_houses.left_side_container {
    height: 320px;
  }
  .recreation_class.left_side_container {
    height: 300px;
  }
  .main_holiday_houses.main_container {
    height: 1031px;
  }
  .main_recreation_class.main_container {
    height: 1011px;
  }
  .right_side_container {
    height: 711px;
    width: 100%;
  }
  .heading_container {
    width: 100%;
  }
  .decorative {
    width: 100%;
  }
  .additional_description {
    width: 100%;
    margin-left: 0;
  }
  .paragraph_two {
    width: 100%;
  }
  .content {
    margin-top: 0;
    padding-top: 4vh;
  }
}
@media (max-width: 700px) {
  .left_side_container {
    height: 320px;
  }
  .holiday_houses.left_side_container {
    height: 340px;
  }
  .main_holiday_houses.main_container {
    height: 1051px;
  }
}

@media (max-width: 576px) {
  .left_side_container {
    height: 360px;
    padding-left: 15px;
  }
  .main_container {
    height: 673px;
  }
  .holiday_houses.left_side_container {
    height: 370px;
  }
  .main_holiday_houses.main_container {
    height: 683px;
  }
  .main_recreation_class.main_container {
    height: 613px;
  }
  .right_side_container {
    height: 313px;
  }
  .arrow_container {
    width: 44px;
    height: 44px;
    background-color: var(--arrow_white);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  .arrow_container:nth-child(1) {
    bottom: 22px;
    right: 22px;
  }
  .arrow_container:nth-child(2) {
    bottom: 22px;
    right: 88px;
  }
  .arrow_button {
    width: 22px;
  }
}
@media (max-width: 510px) {
  .recreation_class.left_side_container {
    height: 330px;
  }
  .main_recreation_class.main_container {
    height: 643px;
  }
}
@media (max-width: 455px) {
  .left_side_container {
    height: 380px;
  }
  .main_container {
    height: 693px;
  }
  .holiday_houses.left_side_container {
    height: 400px;
  }
  .recreation_class.left_side_container {
    height: 350px;
  }
  .main_holiday_houses.main_container {
    height: 713px;
  }
  .main_recreation_class.main_container {
    height: 663px;
  }
}
@media (max-width: 400px) {
  .left_side_container {
    height: 420px;
  }
  .main_container {
    height: 733px;
  }
  .holiday_houses.left_side_container {
    height: 450px;
  }
  .recreation_class.left_side_container {
    height: 400px;
  }
  .paragraph_two {
    position: relative;
    left: 30%;
    width: 70%;
  }
  .main_holiday_houses.main_container {
    height: 763px;
  }
  .main_recreation_class.main_container {
    height: 713px;
  }
}
@media (max-width: 390px) {
  .left_side_container {
    height: 430px;
  }
  .main_container {
    height: 743px;
  }
  .holiday_houses.left_side_container {
    height: 480px;
  }
  .recreation_class.left_side_container {
    height: 420px;
  }
  .paragraph_two {
    left: 30%;
    width: 70%;
  }
  .main_holiday_houses.main_container {
    height: 793px;
  }
  .main_recreation_class.main_container {
    height: 733px;
  }
}
@media (max-width: 370px) {
  .left_side_container {
    height: 480px;
  }
  .main_container {
    height: 793px;
  }
}
@media (max-width: 330px) {
  .left_side_container {
    height: 500px;
  }
  .main_container {
    height: 813px;
  }
  .holiday_houses.left_side_container {
    height: 530px;
  }
  .recreation_class.left_side_container {
    height: 440px;
  }
  .main_holiday_houses.main_container {
    height: 843px;
  }
  .main_recreation_class.main_container {
    height: 753px;
  }
}
@media (max-width: 310px) {
  .left_side_container {
    height: 480px;
  }
  .main_container {
    height: 793px;
  }
  .paragraph_two {
    left: 20%;
    width: 80%;
  }
}
</style>