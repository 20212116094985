import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/main.css'
import i18n from './i18n'
import VueGtag from "vue-gtag"
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from "@/components/HomeView.vue";
import SpaceForRent from "@/components/SpaceForRent.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/space_for_rent',
        name: 'space_for_rent',
        component: SpaceForRent
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
];

console.log(process.env.BASE_URL);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, _from, savedPosition) {
        const headerHeight = 80;

        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            // const offset = document.querySelector(to.hash)?.offsetTop + headerHeight;

            return {
                el: to.hash,
                behavior: 'smooth',
                top: headerHeight,
            };
        } else {
            return {
                el: "#home",
                top: headerHeight,
                behavior: 'smooth'
            };
        }
    },
});

const app = createApp(App);

app.use(i18n);

app.use(VueGtag, {
    appName: 'Maajo',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: "UA-214178687-1",
        params: {
            send_page_view: true,
        }
    },
});

app.use(router).mount('#app');
