export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
  "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь время течёт неспешно..."])},
  "basic_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место для восстановления сил - без городской суеты."])},
  "basic_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO — это давний семейный проект, целью которого является создание идеального места для истинного отдыха. Это место, где все тщательно продумано и устроено так, чтобы помочь вам вновь обрести внутреннюю гармонию, силу и уверенность. Основные принципы скандинавского минимализма в сочетании с умиротворяющей атмосферой побережья Балтийского моря и чарующих лугов позволяют нашим гостям ощутить настоящий покой. "])},
  "basic_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массивные стеклянные поверхности помогут восстановить связь с природной красотой Латвии. Это глубокое погружение в мирную гармонию сельской местности, не требующее отказа от городских удобств."])},
  "basic_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимализм в интерьере не будет отвлекать от чудес, ожидающих снаружи. Это величественные сосны, шепчущие на ветру наилучшие пожелания. Это бодрый и бойкий можжевельник, который дарит прилив сил каждому, кто на него посмотрит. И, конечно же, это загадочный вереск приморского луга, красота которого не перестает нас удивлять уже много лет."])},
  "holiday_houses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домик для отдыха"])},
  "holiday_houses_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дерево, лен и стекло – материалы, сочетающие в себе лучшие принципы традиционного сельского и современного городского дизайна интерьера."])},
  "holiday_houses_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домики для отдыха MAAJO оборудованы самыми эффективными системами рекуперации и кондиционирования, которые обеспечивают освежающую прохладу летом и приятное тепло зимой."])},
  "holiday_houses_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Места хватит как для всей семьи, так и для большой компании из 5 друзей! Для вашего удобства имеется просторная двуспальная кровать, а также удобные раскладные матрасы: один двуспальный и один односпальный."])},
  "bathroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ванная комната"])},
  "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Терраса"])},
  "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спальня"])},
  "living_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гостиная"])},
  "bedroom_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крепкий, качественный сон – одна из важнейших составляющих хорошей жизни. Поэтому в каждом домике есть отдельная спальня с прихожей, ведущей в остальную часть дома."])},
  "bedroom_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просторная двуспальная кровать и удобные раскладные матрасы (двуспальный и односпальный) позволяют комфортно разместиться компании до 5 гостей. Изготовленные на заказ льняные простыни обеспечивают королевские условия для отличного сна круглый год, ведь лен - это дополнительная прохлада летом и тепло зимой. Спокойная скандинавская цветовая палитра, мягкие пледы, нежнейшие пуховые подушки - и самые сладкие сны на свете могут начаться, стоит только закрыть глаза!"])},
  "bedroom_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Большой стеклянный фасад дарит неповторимые ощущения — будто просыпаешься посреди приморского луга. Первые лучи солнца пытаются выглянуть сквозь края льняных штор, лаская ваше лицо... Заряд энергии на весь день! Кроме того, прямо под рукой находится удобный стол для чтения, удаленной работы или учебы. Вдруг вас настигнет внезапное вдохновение для продуктивного дня? Также в домике есть достаточно быстрая бесплатная Wi-Fi сеть."])},
  "living_room_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чистый холст для вашего творчества."])},
  "living_room_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частью какой истории вы станете сегодня? Быть может, это история об уютном вечере у камина? Или история самого важного письма друзьям, семье, будущему себе? Внезапно за огромным окном что-то бросается в глаза - и через некоторое время у вас получается запечатлеть беглый набросок...сидя на удобном льняном диване... рядом с невероятным пейзажем... Мягкая энергетика домика поможет подтолкнуть скрытое вдохновение, которое живет в каждом из нас! "])},
  "living_room_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверенное сочетание дерева, льна и стекла MAAJO создает самые уютные условия для вашего благополучия и процветания."])},
  "terrace_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блестящий танец утренней росы на траве или колыбельная вечернего тумана — в MAAJO вы сможете комфортно наслаждаться общением с природой в течение всего дня."])},
  "terrace_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый домик имеет две открытые террасы: одна расположена в сторону юго-востока, другая смотрит на северо-запад. Поэтому вы всегда сможете выбрать условия по настроению – можно погреться на солнышке или отдохнуть в тени. Террасы оборудованы обеденными столами, сиденьями, удобными креслами и шезлонгами."])},
  "terrace_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "bathroom_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утро, вечер или день — всё едино, всё для вас. "])},
  "bathroom_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек на 60% состоит из воды, поэтому нет ничего лучше освежающего душа в нужный момент! Классический минимализм белой плитки здесь переплетается с продуманным, компактным и функциональным дизайном. Дополнительный комфорт обеспечивает пол с подогревом."])},
  "bathroom_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "holiday_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Со вкусом оформленная кухня в темных тонах полностью оборудована всей необходимой бытовой техникой: плитой, духовкой, микроволновой печью, холодильником, посудомоечной машиной и даже стиральной машиной. Мы всё подготовили таким образом, чтобы вам в MAAJO всего хватало. За солидным обеденным столом легко смогут разместиться до 5 человек. Не забудьте насладиться чашечкой самого лучшего кофе Illy или нежным, успокаивающим латвийским травяным чаем!"])},
  "holiday_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невероятно удобный льняной диван по ощущениям скорее напоминает мягкое облако. На нём чудесный вид из окна и тепло современного камина познаются особенного ярко. Полное расслабление и замедление — как при медитации. "])},
  "sustainability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устойчивое развитие"])},
  "sustainability_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы верим в устойчивое развитие и хозяйство — нашу общую ответственность перед планетой."])},
  "sustainability_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Именно поэтому наши домики для отдыха построены из кросс-ламинированной древесины (CLT), которая отвечает всем требованиям современной архитектуры, экологичности и ответственной добыче материалов."])},
  "sustainability_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для отделки фасада мы выбрали технику обожженного дерева. Это самый естественный и безопасный способ защитить домик от любой непогоды. Кроме того, этот метод помогает подчеркнуть скрытую красоту фактуры дерева. "])},
  "recreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досуг"])},
  "recreation_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом живописном уголке Латвии точно есть на что посмотреть, чем заняться и чем насладиться!"])},
  "recreation_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете оседлать велосипед и отправиться исследовать ближайшие пляжи или деревни, где на каждом шагу скрываются увлекательные истории, а также настоящие мастера своего дела, ремесленники и рыболовы. "])},
  "recreation_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или устройте романтический пикник на уединенном пляже по дороге в Упесгриву, где ласковые волны будут шептать вам древнейшие тайны."])},
  "valgalciems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валгалциемс"])},
  "valgalciems_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нетронутый пляж Валгалциемса"])},
  "valgalciems_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мало кто знает об этом живописном месте. А сколько птиц! Валгалциемс – латвийская Мекка для орнитологов и любителей птиц."])},
  "valgalciems_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь мы встречали бесчисленное количество лебедей, морских воронов, крачек, крохалей, даже редких пеганок и орланов! А если вы хотите хорошенько искупаться, рекомендуем немного прогуляться (1-3 км) в сторону Упесгривы."])},
  "valgalciems_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Там открывается изумительный вид, который идеально подойдет для пикника на свежем воздухе. Кроме того, в заливе Упесгрива гораздо меньше всяких водорослей в безветренную погоду.  "])},
  "upegriva_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пляж Упесгрива"])},
  "upegriva_beach_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длинный пляж Упесгривы"])},
  "upegriva_beach_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы когда-нибудь мечтали о частном пляже? Всего 800 метров - и мечта может стать реальностью! Вас ждет пляж протяженностью несколько километров с чистейшим песком. Идеальное место для безмятежного отдыха!"])},
  "upegriva_beach_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "upegriva_beach_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kolka_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пляж Колка"])},
  "kolka_beach_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пляж Колка"])},
  "kolka_beach_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пляж Колка — это место, где Рижский залив встречается с могучим Балтийским морем. Имейте ввиду, что купаться там бывает довольно опасно, но вид красивейший — не пропустите! "])},
  "kolka_beach_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kolka_beach_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "biking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вело-прогулки"])},
  "biking_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В наличии MAAJO 6 взрослых велосипедов и 1 детский велосипед, а также отдельный вело-прицеп. Прицеп предназначен для малышей, он крепится к взрослому велосипеду. Гости обоих домиков MAAJO могут бесплатно пользоваться этими велосипедами."])},
  "biking_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Велосипеды включены в стоимость."])},
  "sup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sup"])},
  "sup_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть на закат — это прекрасно, но у нас есть идея, как вывести это занятие на новый уровень. SUP-доски, вот как! Покатайтесь на любой из шести SUP-досок MAAJO, и вы познаете непередаваемое ощущение свободы. Катание на доске SUP чем-то похоже на прогулку по поверхности воды. Это опыт, который вы не сможете получить на обычной байдарке. Кроме того, гребля на SUP может стать необычной, мягкой и веселой тренировкой для всего тела. Или новой формой медитации. Но не стоит забывать о безопасности на воде! Обязательно используйте специальный ремешок на щиколотке, а также помните о направлении и силе ветра!"])},
  "sup_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUP-досками могут пользоваться гости обоих домиков отдыха MAAJO без дополнительной оплаты. Они включены в стоимость, как и велосипеды. "])},
  "hot_tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Горячая купель"])},
  "hot_tub_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вечером становится слишком прохладно, а заходить внутрь пока не хочется, идеальным решением будет горячая купель, расположенная рядом с террасой каждого домика MAAJO. Разведите огонь и насладитесь магией воды, пузырей и тепла!"])},
  "hot_tub_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это дополнительная услуга. Вы найдете цену и более подробную информацию в материалах бронирования."])},
  "reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтение"])},
  "reading_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правильная книга в правильных руках может стать ступенькой к чему-то великому. Поэтому мы разместили в домиках продуманную коллекцию книг о моде, путешествиях и искусстве."])},
  "sustainability_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы снова сможете стать самодостаточной личностью в самодостаточной среде. Здесь нет ничего лишнего. Здесь всего хватает. Никто и ничто не сможет прервать поток ваших мыслей и энергии. Если вы с уважением отнесетесь к окружающей среде, она вознаградит вас. Ясным умом. Счастьем в сердце. Радостью жизни и благословенным миром.  "])},
  "sustainability_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся уборка и обслуживание, стирка полотенец и белья выполняются только экологически чистыми материалами. Мыло и гель для душа также имеют специальную эко-маркировку, подтверждающую их происхождение. Уборная подключена к системе биоразлагаемых отходов. Для нас очень важно сохранить планету как можно более чистой. "])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эл. почта:"])},
  "maajo_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO BOUTIQUE HOTEL, Бекери, Валгалциемс, Талсинский край, Латвия"])},
  "maajo_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+371 25447117"])},
  "maajo_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infomaajo.com"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши соцсети:"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facebook"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instagram"])},
  "contact_us_additional_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Пожалуйста, уважайте местных жителей. Тихий и спокойный отдых — залог дружелюбных отношений, для нас это очень важно."])},
  "contact_us_additional_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши соседи будут крайне благодарны, если вы будете находиться только на территории MAAJO и пользоваться только предоставленными официальными тропинками к морю. Держаться подальше от соседней земли действительно необходимо. Дело в том, что там находятся охраняемые государством биотопы и редкие виды растений, которые не должны подвергаться опасности в результате деятельности человека. Спасибо за понимание!"])},
  "sia_maajo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA MAAJO"])},
  "registration_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рег.N. 43603084950"])},
  "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридический адрес: Bauskas nov., Gailīšu pag., Uzvara, Buku iela 2, LV-3931"])},
  "bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк: Rietumu Banka"])},
  "bank_acc_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет: LV33RTMB0000700801985"])},
  "book_a_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заказать комнату"])},
  "space_for_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space for rent"])},
  "storage_premises_rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STORAGE PREMISES RENTAL"])},
  "space_for_rent_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO offers 400m2 industrial storage premises rental in \"Ulmales klubs\", Ulmale, Sakas pagasts, Dienvidkurzemes novads, LV-3466, if you are interested, please email us at"])}
}