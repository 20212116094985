<template>
  <div class="intro_container">
    <span id="home"></span>
    <div class="image_container">
      <Transition>
        <img src="@/assets/images/intro1.png" v-if="main_image === 'intro1.jpg'">
      </Transition>
      <Transition>
        <img src="@/assets/images/intro2.png" v-if="main_image === 'intro2.png'">
      </Transition>
      <Transition>
        <img src="@/assets/images/intro3.png" v-if="main_image === 'intro3.png'" loading="lazy">
      </Transition>
      <Transition>
        <img src="@/assets/images/intro4.png" v-if="main_image === 'intro4.png'" loading="lazy">
      </Transition>
      <Transition>
        <img src="@/assets/images/intro5.png" v-if="main_image === 'intro5.png'" loading="lazy">
      </Transition>
    </div>
    <div class="sub_container">
      <div class="intro_text_container">
        <h1>{{ $t('intro_message') }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroView',
  props: {
    background_images: Array,
  },
  data() {
    return {
      main_image: 'intro1.jpg',
      counter: 2,
    }
  },
  mounted() {
    setInterval(() => {

      this.main_image = `intro${this.counter}.png`

      if (this.counter === this.background_images.length) {
        this.counter = 1
      } else {
        this.counter += 1
      }
    }, 3500)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-enter-active, .v-leave-active {
  transition: opacity 3s ease 1s;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.intro_container {
  width: 100%;
  height: calc(100vh - 80px);

  /*  box-shadow: inset 0 0 0 50vw rgba(13, 13, 14, 0.18);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;*/
  overflow: hidden;
  position: relative;
}
.image_container {
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #0D0D0E;
  position: absolute;
  opacity: 0.9;
  top: 0;
  left: 0;
}
.intro_text_container {
  height: 264px;
  width: 639px;
  position: relative;
  top: 35vh;
}
@media (min-width: 1680px) {
  .sub_container {
    width: 1440px;
    margin: auto;
  }
}
@media (max-width: 1680px) {
  .sub_container {
    width: calc(100vw - 160px);
    margin: auto;
  }
}
@media (max-width: 1200px) {
  .sub_container {
    width: calc(100vw - 100px);
    margin: auto;
  }
}
@media (max-width: 1024px) {
  .sub_container {
    width: calc(100vw - 100px);
    margin: auto;
  }
}
@media (max-width: 600px) {
  .intro_container {
    height: calc(100vh - 64px);
  }
}
@media (max-width: 768px) {
  .sub_container {
    width: calc(100vw - 60px);
    margin: auto;
  }
}
@media (max-width: 576px) {
  .sub_container {
    width: calc(100vw - 30px);
    margin: auto;
  }
}
@media (max-width: 500px) {
  .intro_text_container {
    width: 50%;
  }
  .intro_text_container > h1 {
    font-size: 40px;
  }
}
@media (max-width: 350px) {
  .intro_text_container > h1 {
    font-size: 54px;
  }
}

</style>