<template>
  <div class="main_container orig_container">
    <div class="switcher_container">
      <div class="rotation_container">
        <p class="caps_s horizontal" @click="sectionSelect('valgalciems')" :class="[head === 'valgalciems' ? '' : opacityClass]">{{ $t('valgalciems') }}</p>
        <p class="caps_s horizontal" @click="sectionSelect('upegriva_beach')" :class="[head === 'upegriva_beach' ? '' : opacityClass]">{{ $t('upegriva_beach') }}</p>
        <p class="caps_s horizontal" @click="sectionSelect('kolka_beach')" :class="[head === 'kolka_beach' ? '' : opacityClass]">{{ $t('kolka_beach') }}</p>
      </div>
    </div>
    <div class="description_container">
      <div>
        <h2>
          {{ $t(main) }}
        </h2>
      </div>
      <p class="subtitle">
        {{ $t(subhead) }}
      </p>
      <br v-if="$t(paragraph_1)">
      <p class="paragraph_one" v-if="$t(paragraph_1)">
        {{ $t(paragraph_1) }}
      </p>
      <br v-if="$t(paragraph_2)">
      <p class="paragraph_one" v-if="$t(paragraph_2)">
        {{ $t(paragraph_2) }}
      </p>
      <br>
      <img class="map_img_1" :src="require(`@/assets/images/recreation/${map_img_1}`)" loading="lazy">
      <img class="map_img_2" :class="[head === 'valgalciems' ? valgciemsMapDetails : '', head === 'kolka_beach' ? kolkaMapDetails : '']" :src="require(`@/assets/images/recreation/${map_img_2}`)" loading="lazy">
    </div>
    <div class="photo_container">
      <div class="photo_box_1">
        <img :src="require(`@/assets/images/recreation/${img_1_src}`)" loading="lazy">
      </div>
      <div class="photo_box_2">
        <img :src="require(`@/assets/images/recreation/${img_2_src}`)" loading="lazy">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecreationSwitcher',
  data() {
    return {
      head: 'valgalciems',
      main: 'valgalciems_main',
      subhead: 'valgalciems_sub',
      paragraph_1: 'valgalciems_p1',
      paragraph_2: 'valgalciems_p2',
      map_img_1: 'map_1.png',
      map_img_2: 'map_details_1.png',
      img_1_src: 'photo1_1.png',
      img_2_src: 'photo1_2.png',
      valgaciems_visible: false,
      upegriva_visible: true,
      kolka_visible: true,
      opacityClass: 'opacity',
      valgciemsMapDetails: 'valgciems_map_details',
      kolkaMapDetails: 'kolka_map_details',
      main_info: [
        {head: 'valgalciems',
          main: 'valgalciems_main',
          subhead: 'valgalciems_sub',
          paragraph_1: 'valgalciems_p1',
          paragraph_2: 'valgalciems_p2',
          map_img_1: 'map_1.png',
          map_img_2: 'map_details_1.png',
          img_1_src: 'photo1_1.png',
          img_2_src: 'photo1_2.png'},
        {head: 'upegriva_beach',
          main: 'upegriva_beach_main',
          subhead: 'upegriva_beach_sub',
          paragraph_1: 'upegriva_beach_p1',
          paragraph_2: 'upegriva_beach_p2',
          map_img_1: 'map_2.png',
          map_img_2: 'map_details_2.png',
          img_1_src: 'photo2_1.png',
          img_2_src: 'photo2_2.png'},
        {head: 'kolka_beach',
          main: 'kolka_beach_main',
          subhead: 'kolka_beach_sub',
          paragraph_1: 'kolka_beach_p1',
          paragraph_2: 'kolka_beach_p2',
          map_img_1: 'map_3.png',
          map_img_2: 'map_details_3.png',
          img_1_src: 'photo3_1.png',
          img_2_src: 'photo3_2.jpg'},
      ],
    }
  },
  methods: {
    sectionSelect(section) {
      let index = 0
      switch (section) {
        case 'valgalciems':
          index = 0
          this.bedroom_visible = !this.bedroom_visible
          break
        case 'upegriva_beach':
          index = 1
          this.terrace_visible = !this.terrace_visible
          break
        case 'kolka_beach':
          index = 2
          this.bathroom_visible = !this.bathroom_visible
          break
      }
      this.head = this.main_info[index].head
      this.main = this.main_info[index].main
      this.subhead = this.main_info[index].subhead
      this.paragraph_1 = this.main_info[index].paragraph_1
      this.paragraph_2 = this.main_info[index].paragraph_2
      this.map_img_1 = this.main_info[index].map_img_1
      this.map_img_2 = this.main_info[index].map_img_2
      this.img_1_src = this.main_info[index].img_1_src
      this.img_2_src = this.main_info[index].img_2_src
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main_container {
  margin-top: 12vh;
  margin-bottom: 12vh;
  display: flex;
  /*width: calc(100% - 80px);*/
}
.caps_s {
  /*float: left;*/
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  margin-left: 20px;
}
.caps_s:hover {
  cursor: pointer;
}
.caps_s:nth-child(1) {
  height: 100px;
  margin-top: 130px;
}
.caps_s:nth-child(2),.caps_s:nth-child(3) {
  margin-top: 50px;
}
.rotation_container {
  /*transform: rotate(-90deg);*/
  height: 200px;
  width: 100px;
}
.description_container {
  width: calc((100% - 100px)*0.43);
  /*width: 43%;*/
}
.map_img_1 {
  width: 80%;
}
.map_img_2 {
  margin-top: 5px;
  width: 78%;
  margin-left: 3px;
}
img.valgciems_map_details {
  width: 50%;
}
img.kolka_map_details {
  width: 55%;
}
.photo_container {
  width: calc((100% - 100px)*0.57);
  /*width: 57%;*/
  display: flex;
  justify-content: space-between;
}
.photo_box_1 {
  width: 65%;
}
.photo_box_2 {
  width: 29.11%;
}
.photo_box_1 > img, .photo_box_2 > img {
  width: 100%;
}

h2 {
  width: 80%;
  margin-right: 5%;
}
.subtitle {
  width: 80%;
  margin-top: 2vh;
}
.paragraph_one {
  width: 80%;
}
.opacity {
  opacity: 0.3;
}
@media (max-width: 1440px) {
  .photo_container {
    margin-top: 5vh;
  }
}
@media (max-width: 1300px) {
  .additional_text {
    display: none;
  }
  .photo_container {
    margin-top: 10vh;
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 1050px) {
}
@media (max-width: 744px) {
  .main_container {
    display: block;
    margin-top: 4vh;
    margin-bottom: 6vh;
  }
  .rotation_container {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3vh;
  }
  p.caps_s.horizontal {
    writing-mode: horizontal-tb;
    transform: none;
    cursor: pointer;
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
  }
  .caps_s:nth-child(1) {
    margin-top: 130px;
    height: auto;
  }
  .caps_s:nth-child(2),.caps_s:nth-child(3) {
    margin-top: 50px;
  }
  .description_container {
    width: 100%;
  }
  .subtitle {
    width: 100%;
  }
  .paragraph_one {
    width: 100%;
  }
  .additional_text {
    display: block;
  }
  .photo_container {
    width: 100%;
  }
  .map_img_1 {
    width: 65%;
  }
  .map_img_2 {
    margin-top: 5px;
    width: 63%;
    margin-left: 3px;
  }
  .photo_container {
    margin-top: 2vh;
  }
  h2 {
    width: 100%;
    margin-right: 5%;
  }
}
@media (max-width: 600px) {
  .map_img_1 {
    width: 102%;
  }
  .map_img_2 {
    margin-top: 5px;
    width: 98%;
    margin-left: 3px;
  }
  .photo_container {
    margin-top: 3vh;
  }
  h2 {
    width: 100%;
    margin-right: 5%;
  }
  .rotation_container {
    height: 25px;
    position: relative;
  }
  .caps_s.horizontal:nth-child(1) {
    width: 90px;
  }
  .caps_s.horizontal:nth-child(2) {
    width: 115px;
/*    position: absolute;
    top: 40px;*/
  }
  .caps_s.horizontal:nth-child(3) {
    width: 95px;
  }
}
@media (max-width: 450px) {
  .rotation_container {
    width: 96%;
    justify-content: space-between;
    margin: 0 auto 3vh auto;
  }
}
@media (max-width: 370px) {
  .rotation_container {
    width: 88%;
    justify-content: space-between;
    margin: 0 auto 3vh auto;
  }
  .rotation_container {
    height: 60px;
  }
  .caps_s.horizontal:nth-child(1), .caps_s.horizontal:nth-child(3) {
    height: 28px;
  }
  .caps_s.horizontal:nth-child(2) {
    width: 115px;
    position: absolute;
    top: 40px;
    left: calc((100% - 115px) / 2);
  }
}
</style>