export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kur laiks rit lēnāk"])},
  "basic_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieta, kur atgūt spēkus - bez pilsētas kņadas un steigas."])},
  "basic_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO ir ilgi lolots ģimenes projekts ar mērķi radīt ideālo brīvdienu pieredzi. Tā ir vieta, kur itin viss ir pārdomāts un iekārtots tā, lai palīdzētu jums atgūt iekšējo harmoniju, spēku un pārliecību. Skandināvu minimālisma pamatprincipi apvienojumā ar Baltijas jūras piekrastes nomierinošo gaisotni un burvīgajām pļavām ļauj mūsu viesiem pieredzēt patiesu mieru."])},
  "basic_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pamatīgās stikla virsmas palīdzēs atjaunot saikni ar Latvijas dabas skaistumu. Tā ir pilnīga iegremdēšanās mierpilnā lauku harmonijā, kas neprasa atteikties no pilsētas ērtībām."])},
  "basic_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimālisms interjerā nenovērsīs uzmanību no brīnumiem, kas gaida ārā. Tās ir majestātiskās priedes, kas vējā čukst vislabākos vēlējumus. Tie ir ņiprie kadiķīši, kas dāvā jaunu enerģiju katram, kas tos uzlūko. Un, protams, tie ir piejūras pļavas noslēpumainie virši, kuru skaistums nekad nebeigs mūs pārsteigt."])},
  "holiday_houses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brīvdienu mājas"])},
  "holiday_houses_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koks, lins un stikls – materiāli, kas apvieno labākos tradicionālo lauku un moderno pilsētu interjera dizaina principus."])},
  "holiday_houses_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO brīvdienu mājas ir aprīkotas ar visefektīvākajām rekuperācijas un kondicionēšanas sistēmām, kas nodrošina veldzējošu vēsumu vasarā un patīkamu siltumu ziemā."])},
  "holiday_houses_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te pietiks vietas gan veselai ģimenei, gan kuplai 5 draugu saimei! Jūsu ērtībai pieejama plaša divguļamā gulta, kā arī komfortabli sarullējamie matrači: viens divvietīgs un viens vienvietīgs."])},
  "bathroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vannasistaba"])},
  "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terase"])},
  "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guļamistaba"])},
  "living_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viesistaba"])},
  "bedroom_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kārtīgs naktsmiers ir viena no būtiskākajām labas dzīves sastāvdaļām. Tāpēc katrā brīvdienu mājā ir nodalīta guļamistaba ar gaiteni, kas ved uz pārējo māju."])},
  "bedroom_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaša divguļamā gulta un ērti sarullējamie matrači (divvietīgs un vienvietīgs) ļauj namiņā komfortabli apmesties līdz 5 viesiem. Pēc īpaša pasūtījuma darinātie lina palagi nodrošina karaliskus apstākļus izcilam miegam visu cauru gadu, sniedzot papildu vēsumu vasarā un siltumu ziemā. Rāmā skandināvu krāsu palete, maigās segas, smalkie dūnu spilveni  – un saldākie sapņi pasaulē var sākties, atliek vien pievērt acis!"])},
  "bedroom_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lielā stikla fasāde sniedz pavisam brīnumainu sajūtu – it kā jūs pamostos pašā piejūras pļavas vidū. Pirmie saules stari cenšas ielūkoties gar linu aizkaru maliņām, gādīgi noglāsta seju... Enerģijas lādiņš visai dienai! Turklāt pa rokai ir arī ērts galds lasīšanai, attālinātam darbam vai mācībām, ja rodas iedvesma produktīvai dienai. Namiņā ir pietiekami ātrs bezmaksas Wi-Fi tīkls."])},
  "living_room_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kā audekls jūsu radošumam. "])},
  "living_room_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kādu stāstu jūs uzbursiet šodien? Vai tas ir stāsts par omulīgu vakaru pie kamīna? Vai arī stāsts par iedvesmu rakstīt vēl un vēl pie izturīgā, neuzkrītošā galdiņa? Varbūt tās ir vēstules draugiem, ģimenei, sev nākotnē? Piepeši aiz milzīgā loga kaut kas piesaista skatu – un jau pēc brīža top ātra skice... uz ērtā lina dīvāna... ar neticamo ainavu tepat blakus... Šo telpu maigā enerģija izceļ to slēpto iedvesmu, kas mājo ikvienā no mums. "])},
  "living_room_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO iecienītā koka, lina un stikla kombinācija rada visviesmīlīgāko telpu jūsu labsajūtai."])},
  "terrace_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rīta rasas mirdzošā deja zālājā vai vakara miglas šūpuļdziesmas – MAAJO jūs varat ērti baudīt saikni ar dabu visas dienas garumā."])},
  "terrace_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katram namiņam ir divas āra terases: viena ir vērsta uz dienvidaustrumiem, otra - uz ziemeļrietumiem. Tāpēc jūs vienmēr varēsiet izvēlēties sev tīkamos apstākļus – zvilnēt saulē vai atpūsties ēnā. Terases ir aprīkotas ar pusdienu galdiem, sēdekļiem, ērtiem atpūtas krēsliem un sauļošanās guļamkrēsliem."])},
  "terrace_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "bathroom_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakars, pusdiena vai rīts – jūs būsiet gandarīts. "])},
  "bathroom_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilvēka ķermenis par 60% sastāv no ūdens, tāpēc nekas nelīdzinās patīkamai, atsvaidzinošai dušai īstajā brīdī! Klasisks balto flīžu minimālisms te savijies ar pārdomātu, kompaktu un funkcionālu dizainu. Papildu komfortu sniedz apsildāmā grīda."])},
  "bathroom_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "holiday_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaumīgā tumšo toņu virtuve ir pilnībā aprīkota ar visu nepieciešamo sadzīves tehniku: plīti, cepeškrāsni, mikroviļņu krāsni, ledusskapi, trauku mazgājamo mašīnu un pat veļas mašīnu. Esam pārdomājuši un sagatavojuši visu, lai jums viesošanās laikā nekā netrūktu. Pie pamatīgā pusdienu galda pietiks vietas 5 cilvēkiem. Izbaudiet kārtīgu tasi labākās Illy kafijas vai maigu, nomierinošu Latvijas zāļu tēju!"])},
  "holiday_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neticami ērtais linu dīvāns balstīs jūs kā tāds mākonis. Tas pacels nākamajā līmenī gan skatu baudīšanu pa logu, gan sildīšanos pie mūsdienīgā kamīna."])},
  "sustainability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilgtspējība"])},
  "sustainability_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēs ticam ilgtspējīgai saimniekošanai – kopīgai atbildībai pret mūsu planētu."])},
  "sustainability_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieši tāpēc mūsu brīvdienu mājas ir celtas no ētiski iegūtas, krusteniski līmētas koksnes (CLT), kas atbilst visām mūsdienīgas arhitektūras un ilgtspējības prasībām."])},
  "sustainability_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fasādes apdarei izvēlējāmies dedzinātās koksnes tehniku, jo tas ir dabisks un drošs veids, kā mājiņu pasargāt no jebkuriem laikapstākļiem. Turklāt šī metode palīdz izcelt koka tekstūras slēpto skaistumu. "])},
  "recreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atpūta"])},
  "recreation_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šajā ainaviskajā Latvijas nostūrī ir tik daudz ko redzēt, darīt un baudīt!"])},
  "recreation_head_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūs varat paķert divriteni un doties uz tuvējām pludmalēm vai ciematiem, kur ik uz soļa var sastapt valdzinošus dzīvesstāstus un īstenus amatniekus. "])},
  "recreation_head_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai arī uzrīkojiet romantisku pikniku nomaļā pludmalē pa ceļam uz Upesgrīvu, kur liegi viļņi čukstēs savus noslēpumus tikai jums."])},
  "valgalciems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgalciems"])},
  "valgalciems_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieradinātā Valgalciema pludmale"])},
  "valgalciems_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vien retais zina par šo gleznaino vietu. Un cik daudz te ir putnu! Valgalciems ir Latvijas Meka putnu vērotājiem."])},
  "valgalciems_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te redzēti neskaitāmi gulbji, jūras kraukļi, dadzīši, garknābja gauras, pat retās Sāmsalas dižpīles un jūras ērgļi!  Ja rodas vēlme pēc kārtīgas peldes, iesakām dodiet nelielā pastaigā (1-3 km) Upesgrīvas virzienā."])},
  "valgalciems_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur paveras pārsteidzošs skats, kas būs ideāli piemērots piknikam svaigā gaisā. Turklāt Upesgrīvas līcī bezvēja laikā daudz retāk parādās dažādas ūdenszāles nekā citur."])},
  "upegriva_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upesgrīvas_pludmale"])},
  "upegriva_beach_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garā Upesgrīvas pludmale"])},
  "upegriva_beach_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai esat kādreiz sapņojuši par privāto pludmali? Nieka 800 metru brauciens – un sapnis var kļūt par īstenību! Jūs gaida vairākus kilometrus gara pludmale ar visgaišākajām smiltīm, kur baudīt netraucētu atpūtu."])},
  "upegriva_beach_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "upegriva_beach_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kolka_beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolkas pludmale"])},
  "kolka_beach_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolkas pludmale"])},
  "kolka_beach_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolkas pludmale – vieta, kur Rīgas jūras līcis satiekas ar vareno Baltijas jūru. Peldēties tur var būt diezgan bīstami, taču skats gan ir tāds, ko nedrīkst palaist garām! "])},
  "kolka_beach_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kolka_beach_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "biking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biking"])},
  "biking_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir pieejami 6 pieaugušo velosipēdi un 1 bērnu divritenis, kā arī atsevišķa velo-piekabe. Piekabe ir paredzēta pavisam maziem bērniem, to var piestiprināt pie pieaugušo velosipēda. Abas MAAJO brīvdienu mājas var koplietot šos velosipēdus bez papildu maksas."])},
  "biking_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velosipēdi ir iekļauti cenā."])},
  "sup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sup"])},
  "sup_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai saulrieta vērošanu padarītu pavisam īpašu, piedāvājam iespēju izbraukt ar kādu no sešiem MAAJO SUP dēļiem, kas sniedz neaprakstāmu brīvības sajūtu. Braukt ar SUP dēli ir gandrīz kā staigāt pa ūdens virsmu, tā ir pieredze, ko nevar gūt parastā airu laivā. Tāpat SUP airēšana var kļūt arī par neparastu, saudzīgu un jautru treniņu visam ķermenim. Vai arī jaunu meditācijas veidu. Tikai neaizmirstiet par drošību uz ūdens! Noteikti izmantojiet potītes drošības saiti, kā arī ņemiet vērā vēja virzienu un stiprumu!"])},
  "sup_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUP dēļus bez papildu samaksas var izmantot abu MAAJO brīvdienu māju viesi. Tie ir iekļauti cenā – tāpat kā velosipēdi. "])},
  "hot_tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karstā baļļa"])},
  "hot_tub_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja vakarā kļūst nedaudz par vēsu, bet doties iekšā vēl negribas, ideāls risinājums ir karstā baļļa, kas atrodas blakus katra MAAJO namiņa terasei. Iekuriniet baļļu un ļaujieties burbuļu maģijai!"])},
  "hot_tub_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis ir papildu pakalpojums. Cenu un sīkāku informāciju atradīsiet rezervācijas materiālos."])},
  "reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasīšana"])},
  "reading_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Īstā grāmata īstajās rokās var kļūt par iedvesmas pakāpienu kaut kam diženam. Tāpēc namiņos esam izvietojuši brīnišķīgu modes, ceļojumu un mākslas grāmatu kolekciju."])},
  "sustainability_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šeit jūs varat no jauna kļūt par pašpietiekamu cilvēku pašpietiekamā vidē. Te nav nekā lieka. Te nekā netrūkst. Neviens nespēs iztraucēt jūsu domu un enerģijas plūdumu. Ja cienīsiet apkārtējo vidi – tā jūs atalgos. Ar tīru prātu. Ar laimi sirdī. Ar dzīvesprieku un svētīgu mieru."])},
  "sustainability_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi uzkopšanas un apkalpes darbi tiek veikti tikai ar dabai draudzīgiem materiāliem. Visus dvieļus un gultas veļu mazgājam ar ekoloģiskiem mazgāšanas līdzekļiem. Arī ziepēm un dušas želejai ir speciāls ekomarķējums, kas apliecina to izcelsmi. Labierīcības ir savienotas ar bioloģiski noārdāmo atkritumu sistēmu. Mums ir svarīgi saglabāt planētu pēc iespējas tīrāku, tāpēc katrs mazais solis palīdz."])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saziņai"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adrese"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tālrunis"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasts:"])},
  "maajo_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO BOUTIQUE HOTEL, \"Veģi\", Valgalciems, Talsu novads, Latvia"])},
  "maajo_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+371 25447117"])},
  "maajo_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infomaajo.com"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekot mums:"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facebook"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instagram"])},
  "contact_us_additional_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Ļoti lūdzam cienīt vietējos iedzīvotājus un gādāt par mierīgu, klusu viesošanos."])},
  "contact_us_additional_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mūsu kaimiņi būs ļoti priecīgi un pateicīgi, ja uzturēsieties tikai MAAJO teritorijā! Ir ļoti svarīgi izmantot tikai norādītās taciņas uz jūru, turoties tālāk no kaimiņu zemes. Tur atrodas valsts aizsargājami biotopi un retas augu sugas, ko cilvēka darbība nekādā gadījumā nedrīkst apdraudēt. Paldies par sapratni!"])},
  "sia_maajo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIA MAAJO"])},
  "registration_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reģ. Nr. 43603084950"])},
  "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridiskā adrese: Bauskas nov., Gailīšu pag., Uzvara, Buku iela 2, LV-3931"])},
  "bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banka: Rietumu Banka"])},
  "bank_acc_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konts: LV33RTMB0000700801985"])},
  "privacy_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "privacy_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO use third-party data analytics service providers in order to improve the Services Management Events may use tools from third-party service providers, such as Google Analytics, a web analytics service provided by Google, Inc. (hereinafter - Google”). Google Analytics uses \"cookies\". The information generated by the cookie about your use of the Websites is usually transmitted to a Google server in the USA and stored there. Third parties, including Google, use stored cookies to serve ads based on previous visits by a User to this site. Google may provide a service associated with website activity and internet usage in general and may transfer cookie information to third parties as required by law or as far as third parties process this information on behalf of Google. The IP address provided by Google Analytics as part of Google Analytics will not be merged with other Google data."])},
  "privacy_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can prevent the storage of cookies. In addition, you may prevent the collection by Google of the data generated by the cookie and related to your use of the website (including your IP address) and the processing of this data by Google by using the available browser plug-in: "])},
  "privacy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://tools.google.com/dlpage/gaoptout?hl=en."])},
  "book_a_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rezervēt numuriņu"])},
  "space_for_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznomā telpu"])},
  "storage_premises_rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IZNOMĀ NOLIKTAVAS TELPAS"])},
  "space_for_rent_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAAJO piedāvā iznomāt 400m2 noliktavas telpas adresē - \"Ulmales klubs\", Ulmale, Sakas pagasts, Dienvidkurzemes novads, LV-3466, ja esat ieinteresēti, lūdzam sazināties ar mums uz epastu"])}
}