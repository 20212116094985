<template>
  <div class="footer_info_part">
    <div class="mobile_container">
      <div class="float_left" @click="clickFooter">Privacy</div>
<!--    <div class="float_left">Cookies</div>-->
      <div class="float_left">FAQ</div>
    </div>
    <img class="rights_reserved" src="../assets/icons/rightsReserved.png" alt="All rights reserved 2022">
  </div>
  <div class="privacy_rules" v-if="showRules" v-click-outside="clickOutsideRules">
    <div class="x_icon" @click="clickOutsideRules">x</div>
    <div class="privacy_rules_content">
      <p class="subtitle">{{ $t('privacy_header') }}</p>
      <p class="paragraph_one">
        {{ $t('privacy_text_1') }}
      </p>
      <br>
      <p class="paragraph_one">
        {{ $t('privacy_text_2') }}
        <a class="privacy_link" :href="$t('privacy_link')" target="_blank">{{ $t('privacy_link') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'MainFooter',
  data() {
    return {
      showRules: false,
    }
  },
  methods: {
    clickFooter() {
      this.showRules = !this.showRules
    },
    clickOutsideRules() {
      if(this.showRules === true) {
        this.showRules = !this.showRules
      }
    },
    track () {
      this.$gtag.screenview({
        app_name: 'Maajo',
        screen_name: 'Footer',
      })
    },
/*    track () {
      this.$gtag.event('screen_view',
          {
            app_name: 'maajo',
            screen_name: 'footer'
          })
    }*/
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer_info_part {
    height: 56px;
    width: 100%;
    position: relative;
  }
  .float_left {
    float: left;
    font-size: 12px;
    font-weight: 500;
  }
  .float_left:hover {
    cursor: pointer;
  }
  div:nth-child(1).float_left  {
    left: 51px;
  }
  div:nth-child(2).float_left  {
    left: 106px;
  }
/*  div:nth-child(3).float_left  {
    left: 225px;
  }*/
  .float_left, .rights_reserved {
    position: relative;
    top: 20px;
  }
  .rights_reserved {
    float: right;
    height: 12px;
    width: 156px;
    right: 74px;
    top: 22px;
  }
  .privacy_rules {
    width: 100%;
    max-width: 700px;
    height: calc(100vh - 64px);
    max-height:570px;
    z-index: 10;
    top: 150px;
    left: 50%;
    transform: translate(-50%,5%);
    background-color: #F5F5F5;
    border-radius: 2px;
    position: fixed;
    overflow: auto;
  }
  @media (max-width: 1200px) {
    .privacy_rules {
      top: 110px;
    }
  }
  @media (max-width: 1000px) {
    .privacy_rules {
      top: 90px;
    }
  }
  @media (max-width: 744px) {
    .privacy_rules {
      top: 80px;
      left: 50%;
      width: 100%;
      max-width: 500px;
    }
  }
  @media (max-width: 600px) {
    .privacy_rules {
      top: 30px;
      left: 50%;
      width: 100%;
      max-width: 600px;
      max-height: calc(100vh - 64px);
    }
  }
  .privacy_rules_content {
    width: 80%;
    margin: auto;
    z-index: 11;
  }
  .subtitle {
    margin: 50px 0 25px 0;
  }
  .paragraph_one:nth-child(4) {
    margin-bottom: 50px;
  }
  .privacy_link {
    text-underline-position: under;
  }
  .privacy_link:hover {
    text-decoration: none;
  }
  .x_icon {
    float: right;
    position: relative;
    right: 20px;
    top: 14px;
    font-weight: 500;
    font-size: 20px;
  }
  .x_icon:hover {
    cursor: pointer;
  }
  @media (max-width: 900px) {
    div:nth-child(1).float_left  {
      left: 5vw;
    }
    div:nth-child(2).float_left  {
      left: 10vw;
    }
/*    div:nth-child(3).float_left  {
      left: 13vw;
    }*/
    .rights_reserved {
      right: 5vw;
    }
  }
  @media (max-width: 320px) {
    .footer_info_part {
      height: 94px;
    }
    .mobile_container {
      height: 60px;
      width: 40vw;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .float_left {
      float: none;
      position: static;
    }
    .rights_reserved {
      float: none;
      position: static;
      display: block;
      margin: auto;
    }
  }
</style>
