<template>
  <div class="main_container">
    <div class="flex_container sub_container_1">
      <div class="informational_text">
        <h2>{{ $t('basic_header') }}</h2>
        <br>
        <p class="paragraph_one text_one">{{ $t('basic_text_1') }}</p>
      </div>
      <div class="informational_images">
        <div class="img_box_1">
          <img src="@/assets/images/basic_information/photo_1.png">
        </div>
        <div class="img_box_2">
          <img src="@/assets/images/basic_information/photo_2.png">
        </div>
      </div>
    </div>
    <img class="image_two" src="@/assets/images/basic_information/photo_3.png">
    <div class="sub_container_2">
      <div class="second_content">
        <p class="paragraph_one text_two">{{ $t('basic_text_2') }}</p>
          <div class="image_container">
            <img class="image_three" src="@/assets/images/basic_information/photo_4.png">
            <img class="image_three" src="@/assets/images/basic_information/photo_5.png">
          </div>
        <p class="paragraph_one text_three">{{ $t('basic_text_3') }}</p>
      </div>
      <div class="last_image_content">
        <img class="image_four" src="@/assets/images/basic_information/photo_6.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicInformation',
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.sub_container_1 {
  margin-top: 10vh;
  display: flex;
}
.sub_container_2 {
  margin-top: 10vh;
  margin-bottom: 6vh;
  /*height: 100vh;*/
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

h2 {
  width: 90%;
  margin-top: 4vh;
}

p.text_one {
  width: 90%;
}

.img_box_1 {
  width: 56%;
}
.img_box_2 {
  width: 38.14%;
}
.img_box_1 > img, .img_box_2 > img {
  width: 100%;
}
.image_two {
  width: 100%;
  margin-top: 10vh;
  max-height: 80vh;
  object-fit: cover;
}
.image_three {
  width: 48%;
}
.image_three:nth-child(2) {
  margin-left: 30px;
}
.image_four {
  object-fit: cover;
  width: 100%;
  max-height: 100vh;
}
.last_image_content {
  width: 47%;
}
.text_two {
  width: 48%;
  /*margin-left: 30%;*/
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.text_three {
  /*height: 115px;*/
  width: 40%;
  margin-left: 53%;
  margin-top: 8vh;
}
.informational_text {
  width: 35%;
}
.informational_images {
  display: flex;
  justify-content: space-between;
  width: 65%;
}
.second_content {
  width: 43%;
  position: relative;
}
.image_container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  h2 {
    margin-top: 1vh;
  }
  .text_two {
    width: 48%;
    /*margin-left: 20%;*/
    margin-top: 5vh;
    margin-bottom: 8vh;
  }
}
@media (max-width: 1200px) {
  .informational_text {
    width: 45%;
  }
  .informational_images {
    width: 55%;
  }
  h2 {
    font-size: 28px;
  }
  .text_two {
    margin-top: 5vh;
    margin-bottom: 7vh;
  }
  .text_three {
    margin-left: 53%;
    margin-top: 6vh;
  }
}
@media (max-width: 1100px) {
  .informational_text {
    width: 45%;
  }
  .informational_images {
    width: 55%;
  }
  h2 {
    font-size: 28px;
  }
  .text_two {
    margin-top: 2vh;
    margin-bottom: 6vh;
  }
  .text_three {
    margin-left: 53%;
    margin-top: 4vh;
  }
}
@media (max-width: 1050px) {
  .text_two {
    width: 65%;
    margin-top: 4vh;
    margin-bottom: 5vh;
  }
  .text_three {
    /*height: 115px;*/
    width: 65%;
    margin-left: 35%;
    margin-top: 5vh;
  }
  .informational_images {
    margin-top: 5vh;
  }
}
@media (max-width: 900px) {
  .sub_container_1 {
    display: block;
    margin-top: 5vh;
  }
  h2 {
    width: 100%;
  }
  p.text_one {
    width: 100%;
  }
  .text_three {
    /*height: 115px;*/
    width: 65%;
    margin-left: 35%;
    margin-top: 8vh;
  }
  .informational_text {
    width: 100%;
  }
  .informational_images {
    width: 100%;
  }
  .sub_container_2 {
    margin-top: 5vh;
    display: block;
    margin-bottom: 6vh;
  }
  .image_two {
    margin-top: 5vh;
  }
  .text_two {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    /*height: 100px;*/
    margin-bottom: 5vh;
  }
  .text_three {
    width: 100%;
    margin-left: 0;
    margin-bottom: 5vh;
  }
  .second_content {
    width: 100%;
  }
  .last_image_content {
    width: 100%;
  }
  .image_four {
    width: 100%;
  }
  .text_three {
    margin-top: 4vh;
    /*height: 90px;*/
  }
}

</style>